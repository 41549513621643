// @tailwind base;
// @tailwind components;
// @tailwind utilities;
:root {
  --color-primary:#00568f;
  --color-bg:#ededed;
  --color-primary-dark: #1e1e1e;            /* Violet-500 for buttons, accents */
  --color-bg-dark: #121212;                 /* Deep Navy - very dark background */
  --color-bg-secondary: #272727;            /* Slightly lighter for cards/panels */
  --color-surface: #1E293B;                 /* Used for modals/inputs */
  --color-text-primary: #F8FAFC;            /* Almost white - high contrast */
  --color-text-secondary: #94A3B8;          /* Muted slate for secondary info */
  --color-tertiary: #334155;                /* Dark slate - useful for borders/shadows */
  --color-border: #475569;                  /* Slate border */
  --color-accent: #1e1e1e;                  /* Soft Indigo for hover/focus */
  --color-accent-hover: #A5B4FC; 
  --color-success: #22C55E; 
  --color-warning: #FACC15;                 /* Yellow for alerts */
  --color-error: #EF4444;        
}

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: var(--color-primary) !important;
  color: #fff;
  font-size: 1em;
}

input[type="number"] {
  -moz-appearance: textfield;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: poppins, "Helvetica Neue", sans-serif;
}

@layer utilities {
  @variants reponsive {
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }
    .no-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
}

@layer base {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

@layer base {
  .dark textarea {
    @apply bg-darkMood-bg-secondary text-darkMood-text-secondary border-darkMood-border focus:ring-blue-400;
  }
  .dark input{
    @apply bg-darkMood-bg-secondary text-darkMood-text-secondary border-darkMood-border focus:ring-blue-400;
  }
  .dark  .multiselect-dropdown .dropdown-btn .selected-item {
    @apply bg-darkMood-bg-secondary text-darkMood-text-secondary border-darkMood-border ;

  }
  .dark .dropdown-list li div:before{
    @apply bg-darkMood-bg-secondary text-darkMood-text-secondary border-darkMood-border focus:ring-blue-400;

  }
  .dark .multiselect-item-checkbox input[type="checkbox"]:checked + div:before{
    @apply bg-darkMood-bg-secondary text-darkMood-text-secondary border-darkMood-border focus:ring-blue-400;

  }
  .dark .multiselect-dropdown .dropdown-btn{
    @apply bg-darkMood-bg-secondary text-darkMood-text-secondary border-darkMood-border focus:ring-blue-400;

  }
  .dark select{
    @apply bg-darkMood-bg-secondary text-darkMood-text-secondary border-darkMood-border focus:ring-blue-400;

  }
  
  // .multiselect-dropdown .dropdown-btn .selected-item span {
  //   @apply white-space: nowrap;
  // }
  
  .dark .multiselect-dropdown {
    @apply bg-darkMood-bg-secondary text-darkMood-text-primary border-darkMood-border

  }
  
  .dark .multiselect-dropdown .dropdown-btn {
    @apply bg-darkMood-bg-secondary text-[#f0f0f0] border-darkMood-border;
  }
  
  .dark .multiselect-dropdown .dropdown-list {
    @apply bg-darkMood-bg-secondary border-darkMood-border;
  }
  
  .dark .multiselect-dropdown .dropdown-list ul li {
    @apply text-[#dddddd];
  }
  
  .dark .multiselect-dropdown .dropdown-list ul li:hover {
    @apply bg-darkMood-bg-secondary;
  }
  
  .dark .multiselect-dropdown .dropdown-list ul li:nth-child(2) input {
    @apply bg-darkMood-bg-secondary;
  }
  
  .dark .multiselect-item-checkbox div {
    @apply text-white ;
  }
  
  .dark .multiselect-dropdown .dropdown-list .filter-textbox input {
    @apply bg-darkMood-bg-secondary text-[#f0f0f0] border border-darkMood-border;
  }
  
  .dark .multiselect-dropdown .dropdown-list .filter-textbox input::placeholder {
    color: #aaa; /* Tailwind doesn't support placeholder color via @apply directly, so leave this as-is */
  }
  
}



.zone-label {
  font-size: 10px;
  font-weight: bold;
  color: black;
  background: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #9ca3af;

}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: var(--color-primary);
}

.mat-slide-toggle:not(.mat-checked):not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #9ca3af !important;
}

.mat-slide-toggle:not(.mat-checked):not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: red;
}

.multiselect-dropdown .dropdown-btn {
  background-color: #fff;
  overflow: hidden;
}

.multiselect-dropdown .dropdown-btn .selected-item {
  background: var(--color-primary) !important;
  overflow: hidden;
  margin-bottom: 10px;
}

.dropdown-list li div:before {
  border: 2px solid var(--color-primary) !important;
}

.multiselect-item-checkbox input[type="checkbox"]:checked + div:before {
  background: var(--color-primary) !important;
}



